import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const AccessibilityStatement = () => (
  <div style={{ marginBottom: '1%',backgroundColor: 'white',color: '#0B3C85', padding: '20px' , borderRadius: '8px' , boxSizing: 'content-box'}}>
    <h2 style={{ fontSize: 'x-large' }}>Accessibility Statement</h2>
    Our website is written according to the World Wide Web Consortium (W3C) Web Content Accessibility Guidelines (WCAG) 2.1 Level AA requirements.
    <br />
    If you encounter any difficulties or obstacles while browsing this website, please contact us 
    <a href="mailto:accessibility@tecky.io" style={{color: '#0B3C85'}}> accessibility@tecky.io</a>
    <br />
    <a href="https://www.ienterprise.com.hk/accessible-website-empowered-by-ienterprise-accessibility-center/" target="_blank" style={{color: '#0B3C85'}}>
      <StaticImage
            src='../images/w3c21AA.png'
            style={{ maxWidth: '150px', padding: 0, paddingBottom: '0.5%' }}
            alt="Web Content Accessibility Guidelines (WCAG) 2.1 at Level AA"
          />
      <br />
      Empowered by iEnterprise Accessibility Center
    </a>
    <br />
    <a href="https://ieac.ienterprise.com.hk/empower/ta" target="_blank" style={{color: '#0B3C85'}}>
        <StaticImage
            src='../images/hk_web_accessibility_logo.png'
            style={{ maxWidth: '150px', padding: 0, backgroundColor: 'white', borderRadius: '12px' }}
            alt='hk web accessibility logo'
          />
      <br />
      Web Content Accessibility Guidelines (WCAG) Conformance Update
    </a>
  </div>
);

export default AccessibilityStatement;
