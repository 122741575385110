import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Container from '@components/Container'
import { useTranslation } from 'react-i18next'
import SEO from '@components/SEO'
import { css } from '@emotion/react'
import AccessibilityStatement from '../components/AccessibilityStatement'

const AccessibiltyPage = () => {
	const { t } = useTranslation()

	return (
		<Container title={t('index.accessibility_statement')}>
			<SEO title={t('index.accessibility_statement')} keywords={[]} />
			<h1>{t('index.accessibility_statement')}</h1>

			<div>
				<AccessibilityStatement />
			</div>

			<div
				css={css`
					text-align: left;
				`}>
				<h2>
					Accessibility Statement for{' '}
					<span className='basic-information website-name'>
						Tecky Academy Website
					</span>
				</h2>
				<p>
					This is an accessibility statement from{' '}
					<span className='basic-information organization-name'>
						Tecky Academy Limited
					</span>
					.
				</p>
				<h3>Measures to support accessibility</h3>
				<p>
					<span className='basic-information organization-name'>
						Tecky Academy Limited{' '}
					</span>
					takes the following measures to ensure accessibility of{' '}
					<span className='basic-information website-name'>
						Tecky Academy Website
					</span>
					:
				</p>
				<ul className='organizational-effort accessibility-measures'>
					<li>
						Include accessibility throughout our internal policies.
					</li>
					<li>
						Appoint an accessibility officer and/or ombudsperson.
					</li>
					<li>
						Provide continual accessibility training for our staff.
					</li>
					<li>
						Assign clear accessibility goals and responsibilities.
					</li>
				</ul>
				<h3>Conformance status</h3>
				<p>
					The{' '}
					<a href='https://www.w3.org/WAI/standards-guidelines/wcag/'>
						Web Content Accessibility Guidelines (WCAG)
					</a>{' '}
					defines requirements for designers and developers to improve
					accessibility for people with disabilities. It defines three
					levels of conformance: Level A, Level AA, and Level AAA.{' '}
					<span className='basic-information website-name'>
						Tecky Academy Website
					</span>
					is
					<span
						className='basic-information conformance-status'
						data-printfilter='lowercase'>
						fully conformant
					</span>
					with
					<span className='basic-information conformance-standard'>
						<span data-negate=''>WCAG 2.1 level AA</span>.
					</span>
					<span>
						<span className='basic-information conformance-status'>
							Fully conformant
						</span>
						means that
						<span className='basic-information conformance-meaning'>
							the content fully conforms to the accessibility
							standard without any exceptions
						</span>
						.
					</span>
				</p>
				<h3>Feedback</h3>
				<p>
					We welcome your feedback on the accessibility of{' '}
					<span className='basic-information website-name'>
						Tecky Academy Website
					</span>
					. Please let us know if you encounter accessibility barriers
					on{' '}
					<span className='basic-information website-name'>
						Tecky Academy Website
					</span>
					:
				</p>
				<ul className='basic-information feedback h-card'>
					<li>
						Phone:{' '}
						<span className='phone-number p-tel'>
							+852 97256400
						</span>
					</li>
					<li>
						E-mail:{' '}
						<a
							className='email u-email'
							href='mailto:accessibility@tecky.io'>
							accessibility@tecky.io
						</a>
					</li>
					<li>
						Visitor Address:{' '}
						<span className='visitor-address p-extended-address'>
							20B, TML Tower, 3 Hoi Shing Rd,
							Tsuen Wan, Hong Kong
						</span>
					</li>
					<li className='contact-other p-note'>
						Facebook: @tecky.io
						<br />
						Instagram: @tecky.io
						<br />
					</li>
				</ul>
				<p>
					We try to respond to feedback within{' '}
					<span className='feedback responsetime'>
						2 business days
					</span>
					.
				</p>
				<h3>Technical specifications</h3>
				<p>
					Accessibility of{' '}
					<span className='basic-information website-name'>
						Tecky Academy Website
					</span>
					relies on the following technologies to work with the
					particular combination of web browser and any assistive
					technologies or plugins installed on your computer:
				</p>
				<ul className='technical-information technologies-used'>
					<li>HTML</li>
					<li>WAI-ARIA</li>
					<li>CSS</li>
					<li>JavaScript</li>
				</ul>
				<p>
					These technologies are relied upon for conformance with the
					accessibility standards used.
				</p>
				<h3>Limitations and alternatives</h3>
				<p>
					Despite our best efforts to ensure accessibility of{' '}
					<span className='basic-information website-name'>
						Tecky Academy Website
					</span>{' '}
					, there may be some limitations. Below is a description of
					known limitations, and potential solutions. Please contact
					us if you observe an issue not listed below.
				</p>
				<p>
					Known limitations for
					<span className='basic-information website-name'>
						Tecky Academy Website
					</span>
					:
				</p>
				<ol className='technical-information accessibility-limitations'>
					<li>
						<strong>Comments from users in blog section</strong>:
						Uploaded images may not have text alternatives because
						We cannot ensure the quality of contributions. We
						monitor user comments and typically repair issues within
						2 business days. Please contact us via
						accessibility@tecky.io if you encounter an issue.
					</li>
				</ol>
				<h3>Assessment approach</h3>
				<p>
					<span className='basic-information organization-name'>
						Tecky Academy Limited
					</span>
					assessed the accessibility of{' '}
					<span className='basic-information website-name'>
						Tecky Academy Website
					</span>
					by the following approaches:
				</p>
				<ul className='technical-information assessment-approaches'>
					<li>Self-evaluation</li>
					<li>
						External evaluation (conducted by{' '}
						<a
							href='https://www.ienterprise.com.hk/'
							title='iEnterprise'>
							iEnterprise
						</a>
						)
					</li>
				</ul>
				<hr></hr>
				<h3>Date</h3>
				<p>
					This statement was created on
					<span className='basic-information statement-created-date'>
						{' '}
						1 September 2022{' '}
					</span>
					using the{' '}
					<a href='https://www.w3.org/WAI/planning/statements/'>
						W3C Accessibility Statement Generator Tool
					</a>
					.
				</p>
			</div>
		</Container>
	)
}

export default AccessibiltyPage

export const query = graphql`
	query Accessibilty($language: String!) {
		...TranslationFragment
	}
`
